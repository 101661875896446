import React, { useState } from "react";
import Button from "../button/button";
import './enquire.scss';
import BackgroundImage from "gatsby-background-image"


const Enquire = ({ Title, Subtitle, FooterText, CTALabel, CTAUrl, Background }) => {


    const [msgError, setMsgError] = useState(false);

    const [error, setError] = useState({
        nameError: '',
        phoneError: '',
        enquiryError: '',
        aboutError: ''
    });

    const [formEnquiry, setFormEnquiry] = useState({
        pageType: "any questions?",
        name: "",
        phone: "",
        email: "",
        enquiry: "",
        addInfo: "",
        about: "",
    });

    const onUpdateField = e => {
        const nextFormState = {
            ...formEnquiry,
            [e.target.name]: e.target.value,
        };
        setFormEnquiry(nextFormState);
    };

    const validate = async (e) => {

        let nameError = "";
        let phoneError = "";
        let enquiryError = "";
        let aboutError = "";
        let isNotValid = false;

        if (formEnquiry.name === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }
        if (formEnquiry.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;
        }
        if (formEnquiry.enquiry === "") {
            enquiryError = "This field is required.";
            isNotValid = true;
        }
        if (formEnquiry.about === "") {
            aboutError = "This field is required.";
            isNotValid = true;
        }

        setError({ ...error, nameError: nameError, phoneError: phoneError, enquiryError: enquiryError, aboutError: aboutError });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const submitFormEnquiry = async (e) => {

        e.preventDefault();

        let value = await validate();
        console.log(value, "value");
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOsdjfh7M2ZeaWrnn1',
            FormData: JSON.stringify(formEnquiry),
            PageType: 'any questions?'
        };

        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))


        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(formEnquiry).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }


    const handleGtagEnquiryForm = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'enquiry-form');

        }
    }

    return (
        <>

            <BackgroundImage Tag="section" role="img" className="" fluid={Background?.fluid || ["#009CD2"]}>
                <div className="enquire-container">
                    <div className="enquire-content">
                        <div className="header-text">
                            {
                                Title ? <h2>{Title}</h2> : ""
                            }
                            {
                                Subtitle ? <p>{Subtitle}</p> : ""
                            }
                        </div>
                        <div className="input-fields">

                            <form className="uk-grid-small uk-grid" uk-grid="true" onSubmit={submitFormEnquiry}>
                                <div className="uk-width-1-2@s">
                                    <input className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`} type="text" placeholder="Name" name="name" value={formEnquiry.name} onChange={onUpdateField} />
                                </div>
                                <div className="uk-width-1-2@s">
                                    <input className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`} type="text" placeholder="Telephone" name="phone" value={formEnquiry.phone} onChange={onUpdateField} />
                                </div>
                                <div className="uk-width-1-2@s">
                                    <input className="uk-input" type="text" placeholder="Email" name="email" value={formEnquiry.email} onChange={onUpdateField} />
                                </div>
                                <div className="uk-width-1-2@s">
                                    <div className="uk-form-controls">
                                        <select className={`uk-select ${error.enquiryError !== "" ? 'red-border' : 'select-enquire-type'}`} id="form-horizontal-select" placeholder="Choose enquire type" name="enquiry" value={formEnquiry.enquiry} onChange={onUpdateField}>
                                            <option>New boiler</option>
                                            <option>Boiler servicing</option>
                                            <option>Boiler repairs</option>
                                            <option>Free service reminder</option>
                                            <option>Free survey</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="uk-width-1-1 textarea">
                                    <textarea className="uk-input" type="text" placeholder="Additional Information" name="addInfo" value={formEnquiry.addInfo} onChange={onUpdateField} />
                                </div>
                                <div className="uk-width-1-1">
                                    <input className={`uk-input ${error.aboutError !== "" ? 'red-border' : ''}`} type="text" placeholder="Where did you hear about us?" name="about" value={formEnquiry.about} onChange={onUpdateField} />
                                </div>
                                <div className="enquire-btn" onClick={() => handleGtagEnquiryForm()}>
                                    <Button
                                        label={`${loading == false ? CTALabel : 'Loading...'}`}
                                        isSubmit="true"
                                        btnType="secondary"
                                        additionalClass="padding-button"
                                    ></Button>

                                </div>


                            </form>

                        </div>
                    </div>
                    {
                        msgError ? <p className="error-msg-enquiry">One or more fields have an error. Please check and try again.</p> : ""
                    }
                    {
                        message ? <p className="error-message">{message}</p> : ""
                    }
                    {FooterText ? <p className="footer-text">{FooterText}</p> : ""}
                </div>
            </BackgroundImage>

        </>
    )
}
export default Enquire;
import React from 'react';
import './question-list.scss';

import { GatsbyImage } from "gatsby-plugin-image";
import CustomMarkdown from "../../utils/customMarkdown";


const QuestionList = ({ Image, Title, Question, TextPosition }) => {

    return (
        <div className="question-container">

            <div className={`faq-title ${(TextPosition === "left" && Image)? "title-left" : "title-center"}`}>

                <GatsbyImage image={Image?.gatsbyImageData} alt=""></GatsbyImage>
                <CustomMarkdown children={Title}></CustomMarkdown>

            </div>


            <div className="questions">
                <ul uk-accordion="collapsible: true">
                    {
                        Question.map((item, index) => {
                            return (
                                <li className={`li-content ${(index == 0 && !Image) ? "uk-open" : ""}`}>
                                    <a className="uk-accordion-title" href="#">{item.Title}</a>
                                    <div className="uk-accordion-content">
                                        <p>{item.Answer}</p>
                                    </div>
                                </li>
                            )
                        })
                    }

                </ul>
            </div>

        </div>
    )
}

export default QuestionList;
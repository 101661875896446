import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import "../styles/faq.scss";
import "../styles/contact-us.scss";

import Button from "../components/button/button";

import Layout from "../layouts"

import Header from "../components/header/header"

import Enquire from "../components/enquire/enquire"

import QuestionList from "../components/question-list/question-list"



// markup
const FAQ = () => {

    const data = useStaticQuery(graphql`
        {
    
            strapiFaq{
                PageComponents
                Seo {
                  metaDescription
                  metaTitle
                }
              }
              allFile {
                edges {
                  node {
                    publicURL
                    id
                    name
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
        }
    `)





    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        return (imgUrl)
    }

    const [msgError, setMsgError] = useState(false);

    const [form, setForm] = useState({
        pageType: "FAQ page",
        name: "",
        email: "",
        question: ""
    });

    const onUpdateField = e => {
        //console.log(e)
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
    };


    const [error, setError] = useState({
        nameError: '',
    });

    const validate = async (e) => {

        let nameError = "";
        let isNotValid = false;


        if (form.name === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }

        setError({ ...error, nameError: nameError });
        return isNotValid;

    }


    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const submitForm = async (e) => {
        e.preventDefault();
        let value = await validate();

        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOsdjfh7M2ZeaWrnn1',
            FormData: JSON.stringify(form),
            PageType: 'FAQ page'
        };

        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))


        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(form).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }


    return (
        <Layout SEOTitle={data.strapiFaq.Seo.metaTitle} SEODescription={data.strapiFaq.Seo.metaDescription} footerDisclaimer={data.strapiFaq.Seo.FooterDisclaimer}>

            {data.strapiFaq?.PageComponents?.map((item, index) => {
                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>
                                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                <Header key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTALabel={item.FirstCTALabel}
                                    FirstCTAURL={item.FirstCTAURL}
                                    Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    BackgroundColor={item.BackgroundColorHeader}
                                ></Header>
                            </>

                        )

                    case "page-components.fa-qs":
                        return (
                            <QuestionList key={index}
                                Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                Title={item.Title}
                                Question={item.Question}
                                TextPosition={item.TextPosition} />
                        )

                    case "page-components.enquire":
                        return (
                            <Enquire
                                key={index}
                                Title={item.Title}
                                Subtitle={item.Subtitle}
                                FooterText={item.FooterText}
                                CTALabel={item.CTALabel}
                                CTAUrl={item.CTAUrl}
                                Background={item.Background !== null ? getImgUrl(item.Background[0]?.localFile___NODE) : ""}
                            />
                        )


                    default:
                        return ("")
                }
            })}






            <h2 className="page-title">Don't see your question?</h2>
            <h4 className="page-subtitle">Submit yours here...</h4>
            <div className="FAQ-page-container">
                <div className="padding-form uk-flex-center uk-grid" uk-grid="true">

                    <div id="FAQ-form">
                        <form className="uk-grid" uk-grid="true" onSubmit={submitForm}>

                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width">
                                {/* <label className="label-text uk-form-label">Name</label> */}

                                <input
                                    className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={form.name}
                                    onChange={onUpdateField}
                                />
                            </div>

                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width no-padding-left">
                                {/* <label className="label-text uk-form-label">Email</label> */}
                                <input
                                    className="uk-input"
                                    type="text"
                                    placeholder="Email"
                                    aria-label="50"
                                    name="phone"
                                    value={form.phone}
                                    onChange={onUpdateField}
                                />
                            </div>

                            <div className="uk-width-1-1" id="input-margin">
                                {/* <label className="label-text uk-form-label" for="form-stacked-select">Your Question</label> */}
                                <textarea
                                    className="uk-textarea"
                                    rows="5"
                                    placeholder="Your Question"
                                    aria-label="Textarea"
                                    name="addInfo"
                                    value={form.addInfo}
                                    onChange={onUpdateField}
                                ></textarea>
                            </div>

                            <div className="form-footer">
                                <Button
                                    isSubmit="true"
                                    btnType="secondary"
                                    label={`${loading == false ? 'Submit' : 'Loading...'}`}
                                    additionalClass="FAQ-btn"
                                />

                                {
                                    msgError ? <p className="error-message">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                                <p className="required-info">We will only use your information to respond to your enquiry.</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div >

        </Layout >
    )
}

export default FAQ